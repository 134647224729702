var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-autocomplete-demo" } },
    [
      _c(
        "vx-card",
        { attrs: { title: "Basic Autocomplete", "code-toggler": "" } },
        [
          _c("vue-simple-suggest", {
            attrs: { list: _vm.simpleSuggestionList, "filter-by-query": true },
            model: {
              value: _vm.chosen,
              callback: function ($$v) {
                _vm.chosen = $$v
              },
              expression: "chosen",
            },
          }),
          _c("p", { staticClass: "mt-4" }, [
            _vm._v("Chosen element: " + _vm._s(_vm.chosen)),
          ]),
          _c("template", { slot: "codeContainer" }, [
            _vm._v(
              '\n<template>\n  <vue-simple-suggest\n    v-model="chosen"\n    :list="simpleSuggestionList"\n    :filter-by-query="true">\n    <!-- Filter by input text to only show the matching results -->\n  </vue-simple-suggest>\n\n  <p class="mt-4">Chosen element: ' +
                _vm._s(_vm.chosen) +
                "</p>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      chosen: '',\n    }\n  },\n  methods: {\n    simpleSuggestionList() {\n      return [\n        'Vue.js',\n        'React.js',\n        'Angular.js'\n      ]\n    },\n  }\n}\n</script>\n\n<style lang=\"scss\">\n@import \"@/assets/scss/vuexy/extraComponents/autocomplete.scss\";\n</style>\n      "
            ),
          ]),
        ],
        2
      ),
      _c(
        "vx-card",
        { attrs: { title: "Ajax Based Autocomplete", "code-toggler": "" } },
        [
          _c(
            "vue-simple-suggest",
            {
              ref: "suggestComponent",
              attrs: {
                pattern: "\\w+",
                list: _vm.getList,
                "max-suggestions": 10,
                "min-length": 3,
                debounce: 200,
                "filter-by-query": false,
                "prevent-submit": true,
                controls: {
                  selectionUp: [38, 33],
                  selectionDown: [40, 34],
                  select: [13, 36],
                  hideList: [27, 35],
                },
                mode: _vm.mode,
                "nullable-select": true,
                placeholder: "Search information...",
                "value-attribute": "id",
                "display-attribute": "text",
              },
              on: { select: _vm.onSuggestSelect },
              scopedSlots: _vm._u(
                [
                  {
                    key: "misc-item-above",
                    fn: function ({ suggestions, query }) {
                      return [
                        _c("div", { staticClass: "misc-item" }, [
                          _c("span", [
                            _vm._v(
                              "You're searching for '" + _vm._s(query) + "'."
                            ),
                          ]),
                        ]),
                        suggestions.length > 0
                          ? [
                              _c("div", { staticClass: "misc-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(suggestions.length) +
                                      " suggestions are shown..."
                                  ),
                                ]),
                              ]),
                              _c("hr"),
                            ]
                          : !_vm.loading
                          ? _c("div", { staticClass: "misc-item" }, [
                              _c("span", [_vm._v("No results")]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "suggestion-item",
                    fn: function (scope) {
                      return _c(
                        "div",
                        { attrs: { title: scope.suggestion.description } },
                        [
                          _c("div", { staticClass: "text" }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.boldenSuggestion(scope)),
                              },
                            }),
                          ]),
                        ]
                      )
                    },
                  },
                  {
                    key: "misc-item-below",
                    fn: function ({ suggestions }) {
                      return _vm.loading
                        ? _c("div", { staticClass: "misc-item" }, [
                            _c("span", [_vm._v("Loading...")]),
                          ])
                        : _vm._e()
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.model,
                callback: function ($$v) {
                  _vm.model = $$v
                },
                expression: "model",
              },
            },
            [
              _c("div", { staticClass: "g" }, [
                _c("input", { attrs: { type: "text" } }),
              ]),
            ]
          ),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v("Selected element (" + _vm._s(typeof _vm.selected) + "): "),
            _c("pre", { staticClass: "selected hljs" }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.selected) } }),
            ]),
          ]),
          _c("template", { slot: "codeContainer" }, [
            _vm._v(
              '\n<template>\n  <vue-simple-suggest\n    pattern="\\w+"\n    v-model="model"\n    :list="getList"\n    :max-suggestions="10"\n    :min-length="3"\n    :debounce="200"\n    :filter-by-query="false"\n    :prevent-submit="true"\n    :controls="{\n      selectionUp: [38, 33],\n      selectionDown: [40, 34],\n      select: [13, 36],\n      hideList: [27, 35]\n    }"\n    :mode="mode"\n    :nullable-select="true"\n    ref="suggestComponent"\n    placeholder="Search information..."\n    value-attribute="id"\n    display-attribute="text"\n    @select="onSuggestSelect">\n\n    <div class="g">\n      <input type="text">\n    </div>\n\n    <template slot="misc-item-above" slot-scope="{ suggestions, query }">\n      <div class="misc-item">\n        <span>You\'re searching for \'' +
                _vm._s("{{ query }}") +
                '\'.</span>\n      </div>\n\n      <template v-if="suggestions.length > 0">\n        <div class="misc-item">\n          <span>' +
                _vm._s("{{ suggestions.length }}") +
                ' suggestions are shown...</span>\n        </div>\n        <hr>\n      </template>\n\n      <div class="misc-item" v-else-if="!loading">\n        <span>No results</span>\n      </div>\n    </template>\n\n    <div slot="suggestion-item" slot-scope="scope" :title="scope.suggestion.description">\n      <div class="text">\n        <span v-html="boldenSuggestion(scope)"></span>\n      </div>\n    </div>\n\n    <div class="misc-item" slot="misc-item-below" slot-scope="{ suggestions }" v-if="loading">\n      <span>Loading...</span>\n    </div>\n  </vue-simple-suggest>\n\n  <p class="mt-3">Selected element (' +
                _vm._s("{{ typeof selected }}") +
                "): <pre class=\"selected hljs\"><span v-html=\"selected\"></span></pre></p>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      model: null,\n    }\n  },\n  methods: {\n    getList (inputValue) {\n      return new Promise((resolve, reject) => {\n        let url = `https://en.wikipedia.org/w/api.php?origin=*&action=opensearch&namespace=*&search=${inputValue}&limit=10&namespace=0&format=json`\n        fetch(url).then(response => {\n          if (!response.ok) {\n            reject()\n          }\n          response.json().then(json => {\n            json.shift();\n            let result = []\n            const fields = ['text', 'description', 'link']\n            json.forEach((part, i) => {\n              part.forEach((el, j) => {\n                if (!result[j]) {\n                  result.push({\n                    id: j+1\n                  })\n                }\n                result[j][fields[i]] = el\n              })\n            })\n            resolve(result)\n            // resolve([...(json.items || [])])\n          }).catch(e => {\n            reject(e)\n          })\n        }).catch(error => {\n          this.loading = false\n          reject(error)\n        })\n      })\n    },\n    onSuggestSelect (suggest) {\n      this.selected = suggest\n    },\n    boldenSuggestion(scope) {\n      if (!scope) return scope;\n      const { suggestion, query } = scope;\n      let result = this.$refs.suggestComponent.displayProperty(suggestion);\n      if (!query) return result;\n      const texts = query.split(/[\\s-_/\\\\|.]/gm).filter(t => !!t) || [''];\n      return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)','gi'), '$1<b>$2</b>$3');\n    },\n  }\n}\n</script>\n      "
            ),
          ]),
        ],
        2
      ),
      _c(
        "vx-card",
        { attrs: { title: "Mode" } },
        [
          _c("p", { staticClass: "mb-3" }, [
            _vm._v("Determines the event, that triggers "),
            _c("code", [_vm._v("v-model")]),
            _vm._v(". Can be one of "),
            _c("code", [_vm._v("'input'")]),
            _vm._v(" (default) or "),
            _c("code", [_vm._v("'select'")]),
            _vm._v("."),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _vm._v("For example, if "),
            _c("code", [_vm._v("'input'")]),
            _vm._v(
              " is chosen - then v-model will update the value each time an "
            ),
            _c("code", [_vm._v("input")]),
            _vm._v(" event is fired, setting the input's string."),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _vm._v("Same is for "),
            _c("code", [_vm._v("'select'")]),
            _vm._v(
              " - v-model changes only when something is selected from the list, setting the selected value (string, object or whatever) to its argument."
            ),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _vm._v(
              "A proper use-case for it being when one wants to use the component only for selection binding and custom input for text binding:"
            ),
          ]),
          _c(
            "prism",
            { staticClass: "rounded-lg", attrs: { language: "markup" } },
            [
              _vm._v(
                '\n<vue-simple-suggest v-model="selected" mode="select">\n  <input v-model="text">\n</vue-simple-suggest>\n      '
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }